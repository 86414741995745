import React, { useMemo, useState } from 'react';
import DialogActions from '@mui/material/DialogActions';
import cloneDeep from 'helpers/cloneDeep';
import Translation from 'components/data/Translation';
import Button from 'components/ui-components-v2/Button';
import Accordion from 'components/bricks/components/shared/components/accordion';
import CustomizedDialogs from 'components/ui-components/Dialog/components/dialog';
import { EditorLocalScope } from 'components/editor-data/EditorLocalScope';
import MultiInput from 'components/editor-blocks/MultiInput';
import EditorData from 'components/editor-data/EditorData';
import { generateKey } from 'components/template-designer/utils/generateKey';
import { Preset } from 'components/bricks/types/preset';
import Icon from 'components/ui-components-v2/Icon';
import { FileExt, FileType } from 'components/bricks/types/brick.type';
import customPresetDialogSettings from './custom-preset-dialog-inputs';
import { DEFAULT_CUSTOM_PRESET, fileTypeToExtensions } from '../data/custom-presets';

import '../styles/custom-presets-dialog.scss';

interface Props {
    open: boolean;
    preset?: Preset;
    defaultAssetType?: FileType;
    defaultFileExtension?: FileExt;
    onClose: () => void;
    onSave: (newPreset: Preset) => void;
}

const CustomPresetDialog = ({ open, preset, defaultAssetType, defaultFileExtension, onClose, onSave }: Props) => {
    const [customPreset, setCustomPreset] = useState<Preset>(preset || DEFAULT_CUSTOM_PRESET);

    const localScopeUuid = useMemo(() => `i_${customPreset?.identifier || generateKey()}`, [customPreset.identifier]);

    const handleSaveCustomFormats = () => {
        //clean the data here. check the assetType
        const presetToSave = {
            ...customPreset,
            identifier: customPreset.identifier || generateKey()
        };

        onSave(presetToSave);
        onClose();
    };

    const handleOnChange = (newCustomPresetData: Preset) => {
        setCustomPreset((prevPreset) => {
            const updatedData = cloneDeep(newCustomPresetData);

            if (updatedData.assetType !== prevPreset.assetType) {
                // Reset the extra fields for a different asset type
                updatedData.restrictions = DEFAULT_CUSTOM_PRESET.restrictions;
                updatedData.extraFields = DEFAULT_CUSTOM_PRESET.extraFields;
                delete updatedData.recommendations;

                const extensions = fileTypeToExtensions[updatedData.assetType];
                updatedData.fileExtension = extensions && extensions.length > 0 ? extensions[0] : '';
            }

            return updatedData;
        });
    };

    /**
     * Render the interface blocks based on the page structure
     */
    const renderBlocks = () => {
        const additionalVars = { customPreset };
        return (
            <>
                {customPresetDialogSettings.map((item, index) => {
                    if (!EditorData.validateCondition(item, undefined, undefined)) {
                        return <React.Fragment key={index} />;
                    } else {
                        return (
                            <Accordion key={index} title={item.title} square expanded={item.opened}>
                                <MultiInput key={'customPreset'} data={{ ...item, additionalVars }} localScope={localScopeUuid} />
                            </Accordion>
                        );
                    }
                })}
            </>
        );
    };

    const initialData = useMemo(() => {
        if (customPreset) {
            return customPreset;
        } else {
            // default values for new custom presets
            return {
                ...DEFAULT_CUSTOM_PRESET,
                assetType: defaultAssetType || 'image',
                fileExtension: [defaultFileExtension || 'jpg']
            };
        }
    }, [customPreset, defaultAssetType, defaultFileExtension]);

    return (
        <CustomizedDialogs
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            classes={{ paper: 'custom-preset-dialog' }}
            title={preset ? Translation.get('customPresetDialog.editCustomPreset', 'bricks') : Translation.get('customPresetDialog.addCustomPreset', 'bricks')}>
            <div className="custom-preset-dialog__block-wrapper">
                <EditorLocalScope onChange={handleOnChange} localScopeUuid={localScopeUuid} initialData={initialData} renderBlocks={renderBlocks} />
            </div>
            <DialogActions className="custom-preset-dialog__actions">
                <Button onClick={onClose} type="button" color="primary" size="small">
                    {Translation.get('actions.cancel', 'common')}
                </Button>
                <Button onClick={handleSaveCustomFormats} type="button" variant="contained" color="primary" size="medium">
                    {preset ? Translation.get('customPresetDialog.saveChanges', 'bricks') : Translation.get('customPresetDialog.addCustomPreset', 'bricks')}
                    <Icon className="custom-preset-dialog__add-icon">{preset ? 'save' : 'add_circle'}</Icon>
                </Button>
            </DialogActions>
        </CustomizedDialogs>
    );
};

export default CustomPresetDialog;
